import { z } from 'zod';

export const toggleFreeWorklfowsInMarketplaceSchemaResponse = z.object({
  successful_workflows: z
    .array(z.string().nonempty("workflow_name cannot be empty'"))
    .nonempty('successfulWorkflows cannot be empty'),
  failed_workflows: z
    .array(z.string().nonempty("workflow_name cannot be empty'"))
    .nonempty('failedWorkflows cannot be empty'),
  is_free: z.boolean(),
});

export const toggleFreeWorklfowsInMarketplaceBodySchemaResponse = z.object({
  workflow_names: z
    .array(z.string().nonempty("workflow_name cannot be empty'"))
    .nonempty('workflow_names cannot be empty'),
  is_free: z.boolean(),
});
