import { z } from 'zod';

export const SetupAdvancedWorkflowSchemaRequest = z.object({
  workflow_id: z.string().nonempty('workflow_id cannot be empty'),
  config_dict: z.record(z.string().nonempty('config value cannot be empty')),
  documents: z
    .array(z.object({ uri: z.string().nonempty('uri cannot be empty') }))
    .optional(),
});

export const SetupAdvancedWorkflowResponseSchemaRequest = z.object({
  workflow_id: z.string().nonempty('workflow_id cannot be empty'),
  setup_status: z.boolean(),
  setup_error_message: z.string().optional(),
});

export const AddDocumentsAdvancedWorkflowSchemaRequest = z.object({
  workflow_id: z.string().nonempty('workflow_id cannot be empty'),
  documents: z.array(
    z.object({ uri: z.string().nonempty('uri cannot be empty') }),
  ),
});

export const RemoveDocumentsAdvancedWorkflowSchemaRequest = z.object({
  workflow_id: z.string().nonempty('workflow_id cannot be empty'),
  documents: z.array(
    z.object({ uri: z.string().nonempty('uri cannot be empty') }),
  ),
});
