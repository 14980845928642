import { z } from 'zod';

export const createUserBodyRequestSchema = z.object({
  email: z.string().nonempty('email cannot be empty'),
  first_name: z.string().nonempty('first name cannot be empty'),
  last_name: z.string().nonempty('last name cannot be empty'),
  password: z.string().nonempty('password cannot be empty'),
  image: z.string().optional(),
  user_id: z.string().optional(),
});
