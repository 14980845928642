'use client';

import { Tab, Tabs } from '@appkit4/react-components';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { useTranslations } from 'next-intl';
import React, { useCallback, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

const OneTimeHeaderTabs = () => {
  const { workflowId } = useParams();
  const router = useRouter();
  const searchParams = useSearchParams();
  const t = useTranslations();
  const pathname = usePathname();

  const navItems = [
    {
      label: t('home'),
      value: 'home',
      onClick: () => {
        router.push('/home');
      },
      disabled: false,
    },
    {
      label: t('session'),
      value: 'session',
      onClick: () => {
        if (searchParams.has('session')) {
          const newRoute = `/home/onetimeWorkflow/${workflowId}/session?session=${searchParams.get('session')}`;

          router.push(sanitizeHtml(newRoute));
        } else {
          const newRoute = `/home/onetimeWorkflow/${workflowId}/session`;
          router.push(sanitizeHtml(newRoute));
        }
      },
    },
    {
      label: t('resources'),
      value: 'resources',
      onClick: () => {
        if (searchParams.has('session')) {
          const newRoute = `/home/onetimeWorkflow/${workflowId}/resources?session=${searchParams.get('session')}`;
          router.push(sanitizeHtml(newRoute));
        }
      },
      disabled: true,
    },
    {
      label: t('settings'),
      value: 'settings',
      onClick: () => {
        if (searchParams.has('session')) {
          const newRoute = `/home/onetimeWorkflow/${workflowId}/settings?session=${searchParams.get('session')}`;
          router.push(sanitizeHtml(newRoute));
        }
      },
      disabled: true,
    },
  ];

  const initialActiveIndex = useCallback(() => {
    if (pathname.includes('session')) {
      return 1;
    }
    if (pathname.includes('resources')) {
      return 2;
    }
    if (pathname.includes('settings')) {
      return 3;
    }
    return 0;
  }, [pathname]);

  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  useEffect(() => {
    setActiveIndex(initialActiveIndex);
  }, [pathname]);

  const onTabChange = (i: number) => {
    if (i === activeIndex) return;
    setActiveIndex(i);
    navItems[i]?.onClick();
  };

  return (
    <Tabs
      id="chatTabsContainer"
      type="underline"
      activeIndex={activeIndex}
      onTabChange={onTabChange}
    >
      {navItems.map((item) => (
        <Tab
          key={item.label}
          label={item.label}
          value={item.value}
          disabled={item.disabled}
        />
      ))}
    </Tabs>
  );
};

export default OneTimeHeaderTabs;
