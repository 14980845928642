import { z } from 'zod';

export const fileEntry = z.object({
  uri: z.string().nonempty('uri cannot be empty'),
});
export const CloneAndSetupWorkflowBodySchemaRequest = z.object({
  previous_workflow_id: z
    .string()
    .nonempty('previous_workflow_id cannot be empty'),
  destination_workspace_id: z.string().optional(),
  config_dict: z.record(z.string().nonempty('config value cannot be empty')),
  documents: z.array(fileEntry).optional(),
});
